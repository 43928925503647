import Mmenu from "mmenu-js";
import "../styles/menu.css";

document.addEventListener("DOMContentLoaded", () => {
  const menu = new Mmenu("#menu", {
    offCanvas: {
      page: {
        selector: "#page",
      },
      position: "right-front",
    },
    theme: "white",
  });

  const api = menu.API;
  const open = document.getElementById("open-mobile-menu");
  const close = document.getElementById("close-mobile-menu");

  open.addEventListener("click", () => api.open());
  close.addEventListener("click", () => api.close());
});
