// Toggle show password
function togglePassword(checkbox, field, label) {
  field.type = checkbox.checked ? "text" : "password";
  label.innerText = checkbox.checked ? "Hide" : "Show";
}

function handleChange(event) {
  if (!event.target.matches("[data-toggle]")) return;
  else {
    console.log("Password toggled");
    const passwords = Array.from(event.target.form.querySelectorAll("[data-password]"));
    const label = event.target.nextElementSibling;

    passwords.forEach(function (password) {
      togglePassword(event.target, password, label);
    });
  }
}

const accountForms = document.querySelectorAll(".account-form");

accountForms.forEach((form) => {
  form.addEventListener("change", handleChange);
});

// Product filter form
const filterForm = document.getElementById("product-filters");
const clearFilters = document.getElementById("clear-filters");

if (clearFilters) {
  clearFilters.addEventListener("click", () => {
    filterForm.reset();
    console.log("Form cleared");
  });
}
