import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";

const productSliders = document.querySelectorAll(".product-slide");

if (productSliders) {
  productSliders.forEach((slider) => {
    const glide = new Glide(slider, {
      type: "slider",
      perView: 3,
      gap: 20,
      bound: true,
      peek: {
        before: 0,
        after: 150,
      },
      breakpoints: {
        1024: {
          perView: 2,
        },
        640: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
    });
    glide.mount();
  });
}

const bannerSlider = document.querySelector(".banner-slider");

if (bannerSlider) {
  new Glide(bannerSlider, {
    type: "carousel",
    perView: 1,
    autoplay: 4000,
  }).mount();
}

// Product slider only initiated on mobile/tablet devices
const productImages = document.getElementById("product-slides");
const productSlider = new Glide(productImages, {
  type: "slider",
  perView: 1,
  autoplay: 4000,
});

// productSlider.mount();

if (productImages) {
  function setSlider() {
    if (window.innerWidth < 1024) {
      productSlider.mount();
    } else if (window.innerWidth > 1024) {
      productSlider.destroy();
    }
  }
  setSlider();
  window.onresize = setSlider;
}
